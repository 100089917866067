import { useState } from 'react';

import {
  AuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  UserCredential,
  getAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithPopup,
  AuthErrorCodes,
} from 'firebase/auth';
import { isError } from 'lodash';

import useTxt from '../hooks/useTxt';

import { isTextId } from '../localization';

import { routes } from '../routes';

import { ApiError } from './api';

const ORIGINAL_URL_KEY = 'originalUrl';

export const setOriginalUrl = (url: string) => {
  sessionStorage.setItem(ORIGINAL_URL_KEY, url);
};

export const getOriginalUrl = () => {
  return sessionStorage.getItem(ORIGINAL_URL_KEY);
};

export const clearOriginalUrl = () => {
  sessionStorage.removeItem(ORIGINAL_URL_KEY);
};

export const getAndRemoveOriginalUrl = (fallback = '/') => {
  const originalUrl = sessionStorage.getItem(ORIGINAL_URL_KEY);
  clearOriginalUrl();

  return originalUrl ?? fallback;
};

async function signInWithProvider(
  provider: AuthProvider
): Promise<UserCredential | Error | undefined> {
  try {
    const auth = getAuth();

    const credentials = await signInWithPopup(auth, provider);

    return credentials;
  } catch (error) {
    // console.log(error);

    if (isError(error)) {
      return error;
    }
  }
}

const microsoftAuthProvider = new OAuthProvider('microsoft.com');

export async function signInWithMicrosoft(
  callback: (credentials: UserCredential | Error) => Promise<void>
) {
  if (process.env.REACT_APP_MICROSOFT_TENANT_ID) {
    microsoftAuthProvider.setCustomParameters({
      // Optional "tenant" parameter in case you are using an Azure AD tenant.
      // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
      // or "common" for tenant-independent tokens.
      // The default value is "common".
      tenant: process.env.REACT_APP_MICROSOFT_TENANT_ID,
    });
  }

  const userCredentials = await signInWithProvider(microsoftAuthProvider);

  if (!userCredentials) {
    return;
  }

  return callback(userCredentials);
}

export async function signInWithGoogle(
  callback: (credentials: UserCredential | Error) => Promise<void>
) {
  const userCredentials = await signInWithProvider(googleAuthProvider);

  if (!userCredentials) {
    return;
  }

  return callback(userCredentials);
}

const googleAuthProvider = new GoogleAuthProvider();

export async function sendEmailSignInLink(email: string) {
  const auth = getAuth();
  localStorage.setItem('email_key', email);

  return sendSignInLinkToEmail(auth, email, {
    url: `${window.location.origin}${routes.LOGIN_FINISH_SIGN_IN}`,
    handleCodeInApp: true,
  });
}

export async function signInWithEmail(
  authUrl: string,
  callback: (credentials: UserCredential | Error) => Promise<void>
) {
  const auth = getAuth();
  const email = localStorage.getItem('email_key');

  if (!isSignInWithEmailLink(auth, authUrl) || !email || email.length === 0) {
    window.location.pathname = routes.LOGIN;

    return null;
  }

  const userCredentials = await signInWithEmailLink(auth, email, authUrl);

  return callback(userCredentials);
}

export function isAuthError(error: unknown): error is { code: string } {
  if (!error) {
    return false;
  }

  return (
    typeof error === 'object' &&
    Object.prototype.hasOwnProperty.call(error, 'code')
  );
}

export function useAuthErrorMessage(error: unknown): {
  message: string;
  existsWithDifferentCredentialsError: boolean;
} {
  let errorTextId = '';

  errorTextId = isAuthError(error)
    ? `auth.error.${error.code}`
    : 'auth.error.unspecific';

  if (error instanceof ApiError) {
    const backendError = error.getBackendError();

    if (backendError.status === 401) {
      errorTextId = 'auth.error.backend.insufficientRights';
    }
  }

  const errorMessage = useTxt(
    isTextId(errorTextId) ? errorTextId : 'auth.error.unspecific'
  );

  // eslint-disable-next-line no-extra-boolean-cast
  if (!Boolean(error)) {
    return { message: '', existsWithDifferentCredentialsError: false };
  }

  if (!isAuthError(error)) {
    return {
      message: errorMessage,
      existsWithDifferentCredentialsError: false,
    };
  }

  if (error.code === AuthErrorCodes.POPUP_CLOSED_BY_USER) {
    return { message: '', existsWithDifferentCredentialsError: false };
  }

  return {
    message: `${errorMessage}${
      isTextId(errorTextId) ? null : ` (${error.code})`
    }`,
    existsWithDifferentCredentialsError:
      error.code === AuthErrorCodes.NEED_CONFIRMATION,
  };
}

export function useAsyncError() {
  const [error, setError] = useState<unknown>(null);
  const errorMessage = useAuthErrorMessage(error);

  return {
    errorMessage,
    isError: errorMessage.message.length > 0,
    clearError: () => {
      setError(null);
    },
    setError,
  };
}

export function setBaseUrl(email: string) {
  if (email) {
    switch (process.env.REACT_APP_ENVIRONMENT) {
      case 'local':
        localStorage.setItem('baseUrl', 'http://localhost:9000/');
        break;
      case 'development':
        if (email.endsWith('@fira.fi')) {
          localStorage.setItem(
            'baseUrl',
            'https://backend.dev.firapay.fira.fi/'
          );
        }

        if (email.endsWith('@sitedrive.com')) {
          localStorage.setItem(
            'baseUrl',
            'https://backend.dev.firapay.fira.fi/'
          );
        }

        break;
      case 'staging':
        if (email.endsWith('@mangrove.fi')) {
          localStorage.setItem(
            'baseUrl',
            'https://backend.mangrove-test.pay.sitedrive.com/'
          );
        }

        if (email.endsWith('@fira.fi')) {
          localStorage.setItem(
            'baseUrl',
            'https://backend.test.firapay.fira.fi/'
          );
        }

        if (email.endsWith('@sitedrive.com')) {
          localStorage.setItem(
            'baseUrl',
            'https://backend.test.firapay.fira.fi/'
          );
        }

        break;
      case 'production':
        if (email.endsWith('@mangrove.fi')) {
          localStorage.setItem(
            'baseUrl',
            'https://backend.mangrove.pay.sitedrive.com/'
          );
        } else if (email.endsWith('@fira.fi')) {
          localStorage.setItem('baseUrl', 'https://backend.firapay.fira.fi/');
        } else {
          localStorage.setItem(
            'baseUrl',
            'https://backend.demo.pay.sitedrive.com/'
          );
        }

        break;
      default:
        break;
    }
  }
}
