/* eslint-disable camelcase */
/* TODO: these should come from some shared package */

export enum TaskStatus {
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  Planned = 'PLANNED',
}

export type SchedulePageWorkPackagesQuery = {
  __typename?: 'Query';
  scheduleView: {
    __typename?: 'ScheduleView';
    id: string;
    work_package_data_nested: {
      __typename?: 'SvDataNested';
      calendar_updated_at?: any | null;
      work_packages: Array<{
        __typename?: 'SvWorkPackageDataNested';
        id: string;
        name: string;
        parent_id?: string | null;
        work_section_id?: string | null;
        description?: string | null;
        snippet_id?: string | null;
        work_section_locked?: boolean | null;
        is_sum: boolean;
        master: Array<{
          __typename?: 'WorkPackageMasterDataNested';
          team_ids: Array<string>;
          responsible_manager_ids: Array<string>;
          planned_start: any;
          planned_end: any;
          s_low: number;
          s_high: number;
          status: TaskStatus;
          progress_percentage: number;
          cur_dur: number;
          progress_editable: boolean;
          modified_at_epoch: number;
          tasks?: Array<{
            __typename?: 'TaskMasterData';
            id: string;
            actual_end?: any | null;
            status: TaskStatus;
            quantity_amount?: number | null;
          }> | null;
        }>;
        instances: Array<{
          __typename?: 'WorkPackageInstanceDataNested';
          virtual_space_id: string;
          team_ids: Array<string>;
          responsible_manager_ids: Array<string>;
          planned_start: any;
          planned_end: any;
          s_low: number;
          s_high: number;
          status: TaskStatus;
          progress_percentage: number;
          cur_dur: number;
          progress_editable: boolean;
          modified_at_epoch: number;
          tasks?: Array<{
            __typename?: 'TaskInstanceData';
            id: string;
            actual_end?: any | null;
            status: TaskStatus;
            quantity_amount?: number | null;
            s_low: number;
            s_high: number;
            progress_percentage: number;
            cur_dur: number;
          }> | null;
        }>;
        tasks: Array<{
          __typename?: 'SvInfoTaskData';
          id: string;
          class_type: TaskType;
          placeholder: boolean;
          quantity_cost?: number | null;
          quantity_id?: string | null;
          min_resource?: number | null;
          name: string;
        }>;
      }>;
      calendar: Array<{
        __typename?: 'CalendarInterface';
        planned_start: any;
        planned_end: any;
        s_low: number;
        s_high: number;
      }>;
    };
  };
};

export enum DeadlineType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL',
}

export enum TaskType {
  Additional = 'ADDITIONAL',
  Basic = 'BASIC',
  LegacyPrerequisite = 'LEGACY_PREREQUISITE',
  Quality = 'QUALITY',
  Roadblock = 'ROADBLOCK',
  Task = 'TASK',
  WorkItem = 'WORK_ITEM',
  WorkPackage = 'WORK_PACKAGE',
}

export enum ScheduleViewType {
  Gantt = 'GANTT',
  Takt = 'TAKT',
}

export enum TaktTimeType {
  Days = 'DAYS',
  Minutes = 'MINUTES',
  Weeks = 'WEEKS',
}

export type SchedulePageQuery = {
  __typename?: 'Query';
  scheduleView: {
    __typename?: 'ScheduleView';
    id: string;
    name: string;
    virtual_space_namespace_id?: string | null;
    starred_work_package_deadlines: Array<string>;
    schedule: {
      __typename?: 'Schedule';
      id: string;
      name: string;
      work_time_exceptions: Array<{
        __typename?: 'WorkTimeException';
        exception_date: any;
        description?: string | null;
        exception_times?: Array<{
          __typename?: 'WorkTimeExceptionTime';
          start: string;
          end: string;
        }> | null;
      }>;
      timeline_notes: Array<{
        __typename?: 'TimelineNote';
        id: string;
        name: string;
        description?: string | null;
        color: string;
        start_date: any;
        end_date: any;
      }>;
      custom_calendars: Array<{
        __typename?: 'CustomCalendar';
        id: string;
        takt_time: number;
      }>;
    };
    comments: Array<{
      __typename?: 'Comment';
      id: string;
      person_id: string;
      display_name?: string | null;
      text: string;
      created_at: any;
      work_package_id?: string | null;
      virtual_space_id?: string | null;
    }>;
    work_package_order: Array<{
      __typename?: 'WorkPackageOrder';
      work_package_id: string;
      order_number: number;
      lob_name_positions?: any | null;
    }>;
    calendar_exceptions: Array<{
      __typename?: 'CalendarException';
      start: any;
      end: any;
      type: CalendarDayType;
      exception_description?: string | null;
    }>;
    settings?: {
      __typename?: 'ScheduleViewSettings';
      tag_ids?: Array<string> | null;
      team_ids?: Array<string> | null;
      work_package_hierarchy_ids?: Array<string> | null;
      work_section_ids?: Array<string> | null;
    } | null;
  };
  projectBySvId?: {
    __typename?: 'Project';
    name: string;
    account_id: string;
    id: string;
    custom_project_category_id?: string | null;
    work_sections_namespace_id?: string | null;
    account: { __typename?: 'Account'; name: string };
    site: {
      __typename?: 'Site';
      id: string;
      name: string;
      read_only: boolean;
      teams?: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        color: string;
      }> | null;
      users: Array<{
        __typename?: 'User';
        id: string;
        display_name?: string | null;
      }>;
    };
    work_sections_namespace?: {
      __typename?: 'Namespace';
      work_sections?: Array<{
        __typename?: 'WorkSection';
        id: string;
        name: string;
        parent_id?: string | null;
        code: string;
        tags: Array<{ __typename?: 'Tag'; id: string }>;
      }> | null;
    } | null;
    virtual_spaces_namespaces?: Array<{
      __typename?: 'Namespace';
      id: string;
      virtual_spaces?: Array<{
        __typename?: 'VirtualSpace';
        id: string;
        name: string;
        order_number?: number | null;
        parent_id?: string | null;
        spaces: Array<{ __typename?: 'Space'; id: string }>;
      }> | null;
    }> | null;
  } | null;
};

export enum CalendarDayType {
  ExceptionNoWork = 'EXCEPTION_NO_WORK',
  ExceptionWork = 'EXCEPTION_WORK',
  Normal = 'NORMAL',
}

export type RestFinanceGanttViewDataQuery = {
  schedule_view_data: {
    work_package_data_nested_json?: SchedulePageWorkPackagesQuery['scheduleView']['work_package_data_nested'];
    work_package_order: Array<{
      order_number: number;
      work_package_id: string;
    }>;
  };
  project_data?: {
    account_id: string;
    site: { teams?: Array<{ color: string; id: string; name: string }> };
    virtual_spaces_namespaces?: Array<{
      virtual_spaces?: Array<{
        id: string;
        order_number?: number;
        name: string;
        parent_id?: string;
      }>;
    }>;
    work_sections_namespace?: {
      work_sections?: Array<{
        id: string;
        code: string;
        name: string;
        order_number?: number;
        parent_id?: string;
        description?: string;
      }>;
    };
  };
};
